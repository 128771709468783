import "./EditableGridCell.css";
import * as React from "react";
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { DisplayFieldService } from "../DisplayFieldService";
import { Icon, IconButton } from "office-ui-fabric-react";
import { EditorGridCell, getItemValue, GridCellProps } from "./EditorGridCell";

export type FormatterProps<TImportMap> = { item: TImportMap, value: any };
export type EditorProps<TImportMap> = {
    item: TImportMap,
    value: any,
    onChange: (value: any) => void,
    onEditComplete: (value?: any) => void,
    inputRef?: (_: IFormInputComponent) => void;
};

export const EditableGridCell = (props: React.PropsWithChildren<GridCellProps>) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [value, setValue] = React.useState(getItemValue(props));

    return isEditing
        ? <EditorGridCell {...props}
            onClose={() => setIsEditing(false)}
            onEditComplete={(val) => {
                setValue(val);
                props.onEditComplete(val);
            }}
        />
        : <FormatterGridCell {...props} value={value} onEditClick={() => setIsEditing(true)} />;
}

const FormatterGridCell = (props: React.PropsWithChildren<GridCellProps> & { onEditClick: () => void, value: any }) => {
    const onEditClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        props.onEditClick();
    }, [props.withEditIcon]);

    const readonlyCustomControl = props.customFieldElementRender?.[props.field.name]?.({}, props.item, props.field, undefined) === null;
    const isReadonly = props.field.isReadonly || !!props.field.isFake || !!props.readonly || readonlyCustomControl;

    const Formatter = DisplayFieldService.getFieldFormatter(props.field, false, props.entityType);
    return <div className="grid-editable-cell" data-selection-disabled >
        <div className="formatter"
            onClick={!isReadonly && !props.withEditIcon ? onEditClick : undefined} >
            {props.children}
            {!props.children && !!Formatter && <Formatter item={props.item} value={props.value} />}
        </div>
        {!isReadonly && !!props.withEditIcon
            && <div className="grid-edit-button">
                <IconButton iconProps={{ iconName: "Edit" }} onClick={onEditClick} />
            </div>}
        {isReadonly && <span className="readonly-button">
            <Icon iconName="Uneditable" title="This cell is read-only" />
        </span>}
    </div>;
}
