import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    PrimaryButton, IContextualMenuItem, IconButton, DirectionalHint, ContextualMenuItemType,
    Selection, IObjectWithKey, ICommandBarItemProps
} from "office-ui-fabric-react";
import { ApplicationState } from "../../store";
import { RouteComponentProps } from "react-router-dom";
import * as Metadata from "../../entities/Metadata";
import { IEntitiesScreenView, IHeaderProps } from "../common/EntitiesScreenHeader";
import EntitiesScreenBuilder from "../common/EntitiesScreen";
import { IDeletionResult } from "../../store/services/storeHelper";
import { actionCreators, ConvertToObjectiveOrKeyResult, isObjective, KeyResult, KeyResultWrapper, Objective,
    ObjectiveOrKeyResult, OKRState, TITLE_FIELD_ID } from "../../store/ObjectivesListStore";
import * as Notifications from "../../store/NotificationsStore";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from "../../store/views";
import { UserState } from "../../store/User";
import { contains, CommonOperations, canCreate, canUpdate } from "../../store/permissions";
import Spinner from "../common/Spinner";
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import { Dictionary, EntityType } from "../../entities/common";
import { FilterHelper } from "../../store/objective/filters";
import { notUndefined, toDate, toDictionaryById } from "../utils/common";
import { IDetailsProps, IListProps } from "../common/extensibleEntity/EntityDetailsList";
import ListSubView from "../views/list/ListSubView";
import EditListSubView from "../views/list/EditListSubView";
import ObjectiveCreation from "./ObjectiveCreation";
import KeyResultEdit from "./KeyResultEdit";
import { default as GenericEntitiesFilter } from "../common/EntitiesFilter";
import { TenantState } from "../../store/Tenant";
import { ITimelineProps } from '../common/extensibleEntity/EntityTimelineList';
import { buildTimelineItem, renderSegmentContent, renderSegmentTooltipContent } from './timeline';
import { Visibility } from '../common/timeline/TimelineSegment';
import { HierarchyManager, IHierarchyItem } from '../utils/HierarchyManager';
import { KeyResultStateDialogs, ObjectiveStateDialogs, OKRStateChangeProps, OKRStateChangeAction,
    ObjectivesToRemoveRemoveDialog, DeletionResultRemoveDialog, KeyResultRemoveDialog, KeyResultsImportDialog,
    SubObjectivesImportDialog, ObjectiveMoveDialog, KeyResultMoveDialog } from './OKRDialogs'
import { HierarchyContainer } from "../utils/HierarchyContainer";
import { Reporting, ReportsNav } from "../utils/reporting";
import { MenuTitleBuilder } from "../MenuTitleBuilder";

const EntitiesFilter = GenericEntitiesFilter<Objective>();
type StateProps = {
    objectives: Objective[];
    objectivesMap: Dictionary<Objective>;
    keyResultsMap: Dictionary<ObjectiveOrKeyResult[]>;
    fields: Metadata.Field[];
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    activeFilter?: Metadata.IFilter<Metadata.BaseFilterValue>;
    autoFilterId: string;
    preFilterId?: string;
    views?: ViewsStore.IViewsState;
    deletionResult: IDeletionResult[] | undefined;
    isLoading: boolean;
    isListUpdating: boolean;
    user: UserState;
    tenant: TenantState;
    objectivesReports: ReportsNav;
};
type ActionProps = {
    objectivesActions: typeof actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};
type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    createSubObjectiveForObjective?: Objective | true;
    createKeyResultForObjective?: Objective;
    importSubObjectiveForObjective?: Objective;
    importKeyResultForObjective?: Objective;
    objectiveToMove?: Objective;
    keyResultToMove?: KeyResultWrapper;
    objectivesToRemove: Objective[];
    keyResultToRemove?: KeyResultWrapper;
    canEdit: boolean;
    canManageConfiguration: boolean;
    selectedItems: Objective[];

    isListViewEdit: boolean;
    isTimelineViewEdit: boolean;

    preFilter: Metadata.PreFilter<Objective>;
    entityFilterHelper: Metadata.IEntityFilterHelper<Objective>;

    obectiveStateChangeProps?: OKRStateChangeProps;
    krStateChangeProps?: OKRStateChangeProps;
};

const EntitiesScreen = EntitiesScreenBuilder<ObjectiveOrKeyResult>();

class ObjectivesList extends React.Component<Props, State> {
    private _selection: Selection;
    private _hierarchy: HierarchyManager<Objective, void>;

    constructor(props: Props) {
        super(props);

        const today = {
            begin: new Date().getBeginOfDay(),
            end: new Date().getEndOfDay()
        };
        const past = (_: Objective) => !!_.attributes.FinishDate && toDate(_.attributes.FinishDate)! < today.begin;
        const future = (_: Objective) => !!_.attributes.StartDate && toDate(_.attributes.StartDate)! > today.end;
        const preFilterOptions: Metadata.PreFilterOption<Objective>[] = [
            { key: "past", name: "Past", predicate: past },
            { key: "current", name: "Current", predicate: _ => !(past(_) || future(_)) },
            { key: "future", name: "Future", predicate: future },
            { key: "my", name: "My Objectives", predicate: (_: Objective) => !!~_.attributes.Manager.findIndex(_ => _.id == props.user.id) }
        ];

        this.state = {
            canEdit: this._canEdit(props),
            canManageConfiguration: this._canManageConfiguration(this.props),
            selectedItems: [],

            isListViewEdit: false,
            isTimelineViewEdit: false,
            objectivesToRemove: [],
            preFilter: Metadata.PreFilter.create(preFilterOptions,
                active => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id, active?.key)),
            entityFilterHelper: new FilterHelper({ fields: this.props.fields })
        };

        this._hierarchy = new HierarchyManager<Objective, void>({
            fieldId: TITLE_FIELD_ID,
            getItemId: (entity) => entity.id,
            getItemParentId: (entity: ObjectiveOrKeyResult) => isObjective(entity) ? entity.attributes.Parent?.id : (entity as KeyResultWrapper).objectiveId,
            getItemCategory: (entity: ObjectiveOrKeyResult) => isObjective(entity) ? 0 : 1
        });

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItems: this._selection.getSelection() as Objective[] });
            },
            getKey: (_) => this._hierarchy.getKey(_ as Objective & IHierarchyItem),
            canSelectItem: isObjective as ((item: IObjectWithKey, index?: number) => boolean)
        });
    }

    componentWillMount() {
        this.props.objectivesActions.loadObjectives();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.fields != nextProps.fields) {
            this.setState({ entityFilterHelper: new FilterHelper({ fields: nextProps.fields }) })
        }
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.objective) || canUpdate(props.user.permissions.objective) || props.objectives.some(_ => _.isEditable);
    }

    private _canManageConfiguration(props: Props) {
        return contains(props.user.permissions.common, CommonOperations.ConfigurationManage);
    }

    private _viewChanged = (view: IEntitiesScreenView<Objective>) => {
        this.setState({ selectedItems: [] });
        this.props.viewsActions.setActiveView(view.url);
    }

    private _getTopLevelObjectives = (objectives: Objective[]): { top: Objective[]; all: Objective[] } => {
        const { objectivesMap } = this.props;
        const { top, all } = objectives
            .map(_ => {
                let all: Objective[] = [_];
                let top = _;
                while (top.attributes.Parent?.id && objectivesMap[top.attributes.Parent?.id]) {
                    top = objectivesMap[top.attributes.Parent?.id];
                    all.push(top);
                }
                return { top, all };
            })
            .reduce((cum, cur) => ({ top: [...cum.top, cur.top], all: [...cum.all, ...cur.all] }), { top: [], all: [] });
        return {
            top: top.filter((val, index, arr) => index == arr.indexOf(val)),
            all: all.filter((val, index, arr) => index == arr.indexOf(val))
        };
    }

    private _clearPreFilter = () => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id);

    public render() {
        if (this.props.isLoading) {
            return <Spinner />;
        }

        const { deletionResult, user, objectives, keyResultsMap } = this.props;
        const { objectivesToRemove, createSubObjectiveForObjective, createKeyResultForObjective,
            importSubObjectiveForObjective, importKeyResultForObjective,
            objectiveToMove, keyResultToMove,
            keyResultToRemove, obectiveStateChangeProps, krStateChangeProps } = this.state;
        const entities = this.applyFilter(objectives);
        const { top, all } = this._getTopLevelObjectives(entities);
        const allWithKeyResults = [...all, ...entities.reduce((cum, cur) => ([...cum, ...(keyResultsMap[cur.id] || [])]), [])];

        return <>
            {this.props.objectives.length == 0
                ? <EmptyEntitiesScreen
                    className="objective"
                    title="objectives"
                    description="Define objectives for your organization">
                    <PrimaryButton disabled={!canCreate(user.permissions.objective)} text="Create Objective" onClick={() => this.setState({ createSubObjectiveForObjective: true })} />
                </EmptyEntitiesScreen>
                : <HierarchyContainer<ObjectiveOrKeyResult, void>
                    items={top}
                    allItems={allWithKeyResults}
                    expandIds={all.map(_ => _.id)}
                    hierarchy={this._hierarchy}>
                    <EntitiesScreen
                        canManageConfiguration={this.state.canManageConfiguration}
                        clearPreFilter={this._clearPreFilter}
                        title="objectives"
                        activeViewType={this.props.views!.activeViewType}
                        viewChanged={this._viewChanged}
                        fields={this.props.fields}
                        entities={top}
                        entitiesIsUpdating={this.props.isListUpdating}
                        views={[this.getListView(), this.getTimelineView()]}
                        filter={{
                            activeFilter: this.props.activeFilter,
                            autoFilterId: this.props.autoFilterId,
                            getAttributeValue: this.getAttributeValue,
                            onFilterRender: this._renderFilter
                        }}
                        headerProps={this.getHeaderProps()}
                        router={{
                            history: this.props.history,
                            match: this.props.match,
                            location: this.props.location
                        }}
                        baseUrl="/objectives"
                        canEdit={this.state.canEdit}
                        selectedItemIds={this.state.selectedItems.map(_ => _.id)}
                    />
                </HierarchyContainer>}
            {!!objectivesToRemove.length && <ObjectivesToRemoveRemoveDialog
                onClose={() => this.setState({ objectivesToRemove: [] })}
                objectivesToRemove={objectivesToRemove} />}
            {deletionResult && <DeletionResultRemoveDialog deletionResult={deletionResult} />}
            {!!keyResultToRemove && <KeyResultRemoveDialog
                toRemove={[keyResultToRemove.keyResult]}
                onClose={() => this.setState({ keyResultToRemove: undefined })}
                onComplete={() => {
                    this.setState({ keyResultToRemove: undefined });
                    this.props.objectivesActions.removeKeyResults(keyResultToRemove.objectiveId, [keyResultToRemove.keyResult.id]);
                }} />}
            {createSubObjectiveForObjective && <ObjectiveCreation onDismiss={() => this.setState({ createSubObjectiveForObjective: undefined })}
                parent={createSubObjectiveForObjective !== true ? createSubObjectiveForObjective : undefined} />}
            {createKeyResultForObjective && <KeyResultEdit keyResult={null} onDismiss={() => this.setState({ createKeyResultForObjective: undefined })} parent={createKeyResultForObjective} />}
            {importSubObjectiveForObjective && <SubObjectivesImportDialog
                objective={importSubObjectiveForObjective}
                objectives={objectives}
                onClose={() => this.setState({ importSubObjectiveForObjective: undefined })} />}
            {importKeyResultForObjective && <KeyResultsImportDialog
                objective={importKeyResultForObjective}
                objectives={objectives}
                onClose={() => this.setState({ importKeyResultForObjective: undefined })} />}
            {objectiveToMove && <ObjectiveMoveDialog
                objective={objectiveToMove}
                objectives={objectives}
                onClose={() => this.setState({ objectiveToMove: undefined })} />}
            {keyResultToMove && <KeyResultMoveDialog
                keyresult={keyResultToMove.keyResult}
                objectiveId={keyResultToMove.objectiveId}
                objectives={objectives}
                onClose={() => this.setState({ keyResultToMove: undefined })} />}
            {obectiveStateChangeProps && <ObjectiveStateDialogs
                obectiveStateChangeProps={obectiveStateChangeProps}
                onDismiss={() => this.setState({ obectiveStateChangeProps: undefined })} />}
            {krStateChangeProps && <KeyResultStateDialogs
                krStateChangeProps={krStateChangeProps}
                onDismiss={() => this.setState({ krStateChangeProps: undefined })} />}
        </>;
    }

    private getBulkEditEntities = (): Objective[] => {
        if (this.state.selectedItems.length > 0) {
            return this.state.selectedItems as Objective[];
        }
        return this.applyFilter(this.props.objectives);
    }

    private getHeaderProps = (): IHeaderProps => {
        const { objectivesReports, history } = this.props;

        const reportItems: IContextualMenuItem[] = objectivesReports.packs.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openReport(this.props.history, _);
                }
            }));

        const objectivesItems: IContextualMenuItem[] = objectivesReports.subPacks.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openObjectivesReport(history, _, this.getBulkEditEntities());
                }
            }));

        return {
            entityType: EntityType.Objective,
            createEntityTypes: [EntityType.Objective],
            importEntityTypes: [],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(objectivesItems, reportItems)
            }
        }
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => (
        <EntitiesFilter
            canManageConfiguration={this.state.canManageConfiguration}
            preFilter={this.state.preFilter}
            preFilterId={this.props.preFilterId}
            activeFilter={this.props.activeFilter}
            onActiveFilterChanged={this._onActiveFilterChanged}
            onFilterChanged={this.props.filtersActions.updateFilter}
            isFilterPanelOpen={isFilterPanelOpen}
            toggleFilterPanel={toggleFilterPanel}
            entityType={EntityType.Objective}
            entityFilterHelper={this.state.entityFilterHelper}
        />
    );

    private _onActiveFilterChanged = (id?: string) => {
        this.props.filtersActions.setActiveFilter(id, this.props.preFilterId);
    }

    private applyFilter = (_: Objective[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: Objective): boolean => {
        const { preFilter } = this.state;
        if (Metadata.PreFilter.isItemVisible(preFilter, item, this.props.preFilterId)) {
            return false;
        }

        const { activeFilter } = this.props;
        if (activeFilter) {
            const filterValue = activeFilter.value;
            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(this.props.fields);

            for (const type in filterValue) {
                if (!this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof Metadata.BaseFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderMenu = (entity: ObjectiveOrKeyResult) => {
        const parent = isObjective(entity)
            ? entity.attributes.Parent?.id ? this.props.objectivesMap[entity.attributes.Parent?.id] : undefined
            : this.props.objectivesMap[(entity as KeyResultWrapper).objectiveId];

        const parentState = parent ? parent.attributes.State : OKRState.Open;

        let items: (IContextualMenuItem | undefined)[] = isObjective(entity)
            ? [
                {
                    key: 'objEdit',
                    name: entity.isEditable && entity.attributes.State == OKRState.Open ? 'Edit' : 'View',
                    iconProps: { iconName: entity.isEditable && entity.attributes.State == OKRState.Open ? 'Edit' : 'View' },
                    onClick: () => this.props.history.push(this._getObjectiveLink(entity))
                },
                entity.attributes.State === OKRState.Open && canCreate(this.props.user.permissions.objective) ? {
                    key: 'objNewSubobj',
                    name: "New Sub-Objective",
                    iconProps: { iconName: 'Add' },
                    onClick: () => { this.setState({ createSubObjectiveForObjective: entity }); }
                } : undefined,
                entity.attributes.State === OKRState.Open && canCreate(this.props.user.permissions.objective) ? {
                    key: 'objImport',
                    iconProps: { iconName: 'Import' },
                    name: 'Import Sub-Objective',
                    onClick: () => { this.setState({ importSubObjectiveForObjective: entity }); }
                } : undefined,
                entity.isEditable && entity.attributes.State === OKRState.Open ? {
                    key: "add-key-result",
                    name: "New Key Result",
                    iconProps: { iconName: 'Add' },
                    onClick: () => { this.setState({ createKeyResultForObjective: entity }); }
                } : undefined,
                entity.isEditable && entity.attributes.State === OKRState.Open && canCreate(this.props.user.permissions.objective) ? {
                    key: 'krImport',
                    iconProps: { iconName: 'Import' },
                    name: 'Import Key Result',
                    onClick: () => { this.setState({ importKeyResultForObjective: entity }); }
                } : undefined,
                parentState === OKRState.Open && canCreate(this.props.user.permissions.objective) ? {
                    key: 'objClone',
                    iconProps: { iconName: 'Copy' },
                    name: 'Clone',
                    onClick: () => { this.props.objectivesActions.cloneObjective(entity.id) }
                } : undefined,
                entity.isEditable && parentState === OKRState.Open ? {
                    key: 'objMove',
                    name: 'Move to Objective',
                    iconProps: { iconName: "PPMXCompanyObjectives" },
                    subMenuProps: {
                        items: [
                            {
                                key: 'top',
                                name: 'Top Tevel',
                                disabled: !entity.attributes.Parent,
                                onClick: () => { this.props.objectivesActions.moveObjective(entity.id, null); }
                            },
                            {
                                key: 'ohter',
                                name: 'Other Objective',
                                onClick: () => this.setState({ objectiveToMove: entity })
                            }
                        ]
                    }
                } : undefined,
                entity.isEditable ? { key: 'objDivider1', itemType: ContextualMenuItemType.Divider } : undefined,
                ...entity.isEditable ? getObjectiveStateMenuItems(entity, parent ? { state: parent.attributes.State, id: parent.id } : undefined, {
                    reopen: (entityId: string, state: OKRState) => this.setState({ obectiveStateChangeProps: { entityId: entityId, actionType: OKRStateChangeAction.Reopen } }),
                    close: (entityId: string, state: OKRState) => this.setState({ obectiveStateChangeProps: { entityId: entityId, actionType: OKRStateChangeAction.Close } }),
                    archive: (entityId: string, state: OKRState) => this.setState({ obectiveStateChangeProps: { entityId: entityId, actionType: OKRStateChangeAction.Archive } }),
                    activateToOpen: (entityId: string, state: OKRState) => this.setState({ obectiveStateChangeProps: { entityId: entityId, actionType: OKRStateChangeAction.ActivateToOpen } }),
                    activateToClosed: (entityId: string, state: OKRState) => this.setState({ obectiveStateChangeProps: { entityId: entityId, actionType: OKRStateChangeAction.ActivateToClosed } })
                }) : [],
                entity.isEditable ? { key: 'objDivider2', itemType: ContextualMenuItemType.Divider } : undefined,
                entity.isEditable && parentState === OKRState.Open ? {
                    key: 'objDelete',
                    name: 'Delete',
                    iconProps: { iconName: "Delete", style: parentState == OKRState.Open ? { color: 'red' } : undefined },
                    style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                    onClick: () => { this.setState({ objectivesToRemove: [entity] }); }
                } : undefined
            ]
            : [
                {
                    key: 'krEdit',
                    name: !parent?.isEditable || entity.attributes.State != OKRState.Open ? 'View' : 'Edit',
                    iconProps: { iconName: !parent?.isEditable || entity.attributes.State != OKRState.Open ? 'View' : 'Edit' },
                    onClick: () => this.props.history.push(this._getKeyResultLink(entity as KeyResultWrapper))
                },
                entity.isEditable && parentState === OKRState.Open ? {
                    key: 'krClone',
                    iconProps: { iconName: 'Copy' },
                    name: 'Clone',
                    onClick: () => { this.props.objectivesActions.cloneKeyResults((entity as KeyResultWrapper).objectiveId, [(entity as KeyResultWrapper).keyResult.id]) }
                } : undefined,
                entity.isEditable && parentState === OKRState.Open ? {
                    key: 'krMove',
                    name: 'Move to other Objective',
                    iconProps: { iconName: "PPMXRocket" },
                    onClick: () => { this.setState({ keyResultToMove: entity as KeyResultWrapper }) }
                } : undefined,
                entity.isEditable ? { key: 'krDivider1', itemType: ContextualMenuItemType.Divider } : undefined,
                ...entity.isEditable ? getKeyResultStateMenuItems((entity as KeyResultWrapper).keyResult, { state: parent?.attributes.State!, id: parent?.id!, isEditable: parent?.isEditable! }, {
                    reopen: (entityId: string, state: OKRState, parentId: string | undefined) => this.setState({ krStateChangeProps: { entityId: entityId, objectiveId: parentId!, actionType: OKRStateChangeAction.Reopen } }),
                    close: (entityId: string, state: OKRState, parentId: string | undefined) => this.setState({ krStateChangeProps: { entityId: entityId, objectiveId: parentId!, actionType: OKRStateChangeAction.Close } }),
                    archive: (entityId: string, state: OKRState, parentId: string | undefined) => this.setState({ krStateChangeProps: { entityId: entityId, objectiveId: parentId!, actionType: OKRStateChangeAction.Archive } }),
                    activateToOpen: (entityId: string, state: OKRState, parentId: string | undefined) => this.setState({ krStateChangeProps: { entityId: entityId, objectiveId: parentId!, actionType: OKRStateChangeAction.ActivateToOpen } }),
                    activateToClosed: (entityId: string, state: OKRState, parentId: string | undefined) => this.setState({ krStateChangeProps: { entityId: entityId, objectiveId: parentId!, actionType: OKRStateChangeAction.ActivateToClosed } }),
                }) : [],
                entity.isEditable ? { key: 'krDivider2', itemType: ContextualMenuItemType.Divider } : undefined,
                entity.isEditable && parentState === OKRState.Open ? {
                    key: 'krDelete',
                    name: 'Delete',
                    iconProps: { iconName: "Delete", style: { color: 'red' } },
                    style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                    onClick: () => { this.setState({ keyResultToRemove: entity as KeyResultWrapper }); }
                } : undefined
            ];

        return <div className="menu">
            <IconButton
                menuIconProps={{ iconName: 'More' }}
                menuProps={{
                    directionalHint: DirectionalHint.bottomRightEdge,
                    items: items.filter(notUndefined)
                }}
            />
        </div>;
    }

    private _getObjectiveLink(entity: Objective): string {
        return `/objective/${entity.id}`;
    }

    private _getKeyResultLink(entity: KeyResultWrapper): string {
        return `/objective/${(entity as KeyResultWrapper).objectiveId}?keyresult=${entity.keyResult.id}`;
    }

    private buildItemRender = (): (item: ObjectiveOrKeyResult, index: number, field: Metadata.Field, defaultRender: () => JSX.Element | null) => JSX.Element => {
        return (item: ObjectiveOrKeyResult, index, field, defaultRender) => {
            return defaultRender?.()!;
        };
    }

    private onInlineEditComplete = (field: Metadata.Field, item: ObjectiveOrKeyResult, value: any) => {
        if (isObjective(item)) {
            this.props.objectivesActions.updateObjectiveAttributes(item.id, { [field.name]: value });
        } else {
            const lowerFieldName = (name: string): string => name.charAt(0).toLocaleLowerCase() + name.slice(1);
            this.props.objectivesActions.saveKeyResult(
                (item as KeyResultWrapper).objectiveId,
                {
                    ...(item as KeyResultWrapper).keyResult,
                    [lowerFieldName(field.name)]: value
                });
        }
    }

    private getListView(): IEntitiesScreenView<Objective> {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.fields);
        const fakeMap = toDictionaryById(list.fakeFields);
        const isFieldFake: (f: Metadata.Field) => boolean = _ => !!fakeMap[_.id];
        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: 'list',
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId != id) {
                    this.props.history.push(`/objectives/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/objectives/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            },
            render: (key: string, activeSubView: Metadata.IListSubView, entities: Objective[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    onItemRender: this.buildItemRender(),
                    onInlineEditComplete: this.onInlineEditComplete
                };
                return [<ListSubView
                    key="details-view"
                    type="Details"
                    entities={entities}
                    selection={this._selection}
                    hierarchy={this._hierarchy}
                    entityType={EntityType.Objective}
                    fields={fields}
                    isFieldFake={isFieldFake}
                    sort={list.sortBy}
                    onSortChange={this.props.viewsActions.changeListViewSort}
                    view={activeSubView}
                    listProps={listProps}
                    selectionModeItems={this._buildSelectionModeCommands()}
                    onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                />,
                this.state.isListViewEdit
                    ? <EditListSubView
                        key="create-details-view"
                        entityType={EntityType.Objective}
                        subView={activeSubView}
                        selectedByDefault={this.props.views!.list.selectedByDefault}
                        fields={fields}
                        onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                        onSave={() => {
                            this.props.viewsActions.saveListSubView(activeSubView, 'objectives');
                            this.setState({ isListViewEdit: false });
                        }}
                        onCopy={() => this._onCopyListSubView(activeSubView)}
                        onDismiss={() => this.setState({ isListViewEdit: false })} />
                    : <span key="no-edit"></span>
                ];
            }
        }
    }

    private getTimelineView(): IEntitiesScreenView<Objective> {
        const { timeline } = this.props.views!;
        const { isTimelineViewEdit } = this.state;
        const subViews = timeline.subViews.allIds.map(_ => timeline.subViews.byId[_]);
        const fields = this.props.views!.list.fakeFields.concat(this.props.fields);
        const fakeMap = toDictionaryById(this.props.views!.list.fakeFields);
        const isFieldFake: (f: Metadata.Field) => boolean = _ => !!fakeMap[_.id];
        return {
            icon: 'PPMXTimelineView',
            url: 'timeline',
            subViews,
            activeSubViewId: timeline.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setTimelineActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addTimelineSubView(subView);
                this.props.history.push(`/objectives/timeline/${subView.id}`);
                this.setState({ isTimelineViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (timeline.activeSubViewId != id) {
                    this.props.history.push(`/objectives/timeline/${id}`)
                }
                this.setState({ isTimelineViewEdit: true });
            },
            onCopySubViewClick: this._onCopyTimelineSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeTimelineSubView,
            render: (key: string, activeSubView: Metadata.ITimelineSubView, entities: Objective[]) => {
                const listProps: Partial<IListProps> & ITimelineProps = {
                    buildRow: (objective: Objective) => buildTimelineItem(objective, this.props.fields, Visibility.OnHover),
                    renderSegmentContent: (row) => renderSegmentContent(row, this.props.fields),
                    renderSegmentTooltipContent,
                    onItemMenuRender: this._renderMenu,
                    userQuantization: timeline.quantization,
                    userTimeframe: timeline.timeframe,
                    onScaleChange: (s, q, t, o) => o && this.props.viewsActions.setTimelineScale(o),
                    onItemRender: this.buildItemRender(),
                    onInlineEditComplete: this.onInlineEditComplete
                };
                return <>
                    <ListSubView
                        key="timeline-view"
                        type="Timeline"
                        selection={this._selection}
                        entities={entities}
                        hierarchy={this._hierarchy}
                        entityType={EntityType.Objective}
                        fields={fields}
                        isFieldFake={isFieldFake}
                        sort={timeline.sortBy}
                        onSortChange={this.props.viewsActions.changeTimelineViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectionModeCommands()}
                        onColumnResized={(id, w) => this.props.viewsActions.onTimelineColumnResized(activeSubView.id, id, w)} />
                    {
                        isTimelineViewEdit && <EditListSubView
                            key="create-timeline-view"
                            subView={activeSubView}
                            entityType={EntityType.Objective}
                            selectedByDefault={timeline.selectedByDefault}
                            fields={fields}
                            onChange={changes => {
                                this.props.viewsActions.updateTimelineSubView(activeSubView.id, changes);
                            }}
                            onSave={() => {
                                this.props.viewsActions.saveTimelineSubView(activeSubView, 'objectives');
                                this.setState({ isTimelineViewEdit: false })
                            }}
                            onCopy={() => this._onCopyTimelineSubView(activeSubView)}
                            onDismiss={() => this.setState({ isTimelineViewEdit: false })}
                        />
                    }
                </>
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/objectives/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'objectives', undefined, view.id);
    }

    private _onCopyTimelineSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/objectives/timeline/${subView.id}`);
        this.setState({ isTimelineViewEdit: true });
        this.props.viewsActions.saveTimelineSubView(subView, 'objectives', undefined, view.id);
    }

    private _buildSelectionModeCommands = (): ICommandBarItemProps[] | undefined => {
        if (!this.state.canEdit) {
            return undefined;
        }
        const { selectedItems } = this.state;
        return [
            selectedItems.length === 1 && selectedItems[0].attributes.State === OKRState.Open && canCreate(this.props.user.permissions.objective) ? {
                key: "new-sub-objective",
                text: "New Sub-Objective",
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({ createSubObjectiveForObjective: selectedItems[0] }); }
            } : undefined,
            selectedItems.length === 1 && selectedItems[0].attributes.State === OKRState.Open && selectedItems[0].isEditable ? {
                key: "new-key-result",
                text: "New Key Result",
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({ createKeyResultForObjective: selectedItems[0] }); }
            } : undefined,
            selectedItems.find(_ => _.isEditable) ? {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Objective),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => this.setState({ objectivesToRemove: selectedItems }),
            } : undefined
        ].filter(notUndefined);
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps => {
    const fieldsState = state.fields[EntityType.Objective];
    const fields = fieldsState.allIds.map(_ => fieldsState.byId[_]);
    const filters = FiltersStore.getFilter(state.filters, EntityType.Objective);
    const autoFilterId = Metadata.Filter.getAutoFilterId(filters.all) ?? filters.all[0].id;
    const allObjectives = state.objectives.allIds.map(_ => state.objectives.byId[_]);
    const keyResultsMap = allObjectives.filter(_ => _.keyResults.length)
        .reduce((cum, cur) => ({
            ...cum,
            [cur.id]: cur.keyResults.map<ObjectiveOrKeyResult>(_ => ConvertToObjectiveOrKeyResult(cur, _))
        }), {});
    return {
        objectives: allObjectives,
        objectivesMap: state.objectives.byId,
        keyResultsMap,
        fields,
        filters: filters.all,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        preFilterId: filters.active.preFilterId,
        views: state.views[EntityType.Objective],
        deletionResult: state.objectives.deletionResult,
        isLoading: state.objectives.isLoading,
        isListUpdating: state.objectives.isListUpdating,
        user: state.user,
        tenant: state.tenant,
        objectivesReports: state.tenant.reporting.objectivesReports,
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        objectivesActions: bindActionCreators(actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Objective), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Objective), dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ObjectivesList);

export type OKRStateMenuAction = {
    reopen?: (entityId: string, state: OKRState, parentId: string | undefined, oldState: OKRState) => void,
    close?: (entityId: string, state: OKRState, parentId: string | undefined, oldState: OKRState) => void,
    archive?: (entityId: string, state: OKRState, parentId: string | undefined, oldState: OKRState) => void,
    activateToOpen?: (entityId: string, state: OKRState, parentId: string | undefined, oldState: OKRState) => void,
    activateToClosed?: (entityId: string, state: OKRState, parentId: string | undefined, oldState: OKRState) => void
}

export function getObjectiveStateMenuItems(entity: Objective, parent: { state: OKRState, id: string } | undefined, actions: OKRStateMenuAction): IContextualMenuItem[] {
    const parentState = parent?.state ?? OKRState.Open;
    return entity.isEditable
        ? [
            parentState !== OKRState.Closed && entity.attributes.State === OKRState.Closed && actions.reopen
                ? {
                    key: 'objReopen',
                    name: 'Reopen',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.reopen!(entity.id, OKRState.Open, parent?.id, entity.attributes.State)
                } : undefined,
            parentState === OKRState.Open && entity.attributes.State === OKRState.Archived && actions.activateToOpen
                ? {
                    key: 'objActivateToOpen',
                    name: 'Activate to Open',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.activateToOpen!(entity.id, OKRState.Open, parent?.id, entity.attributes.State)
                } : undefined,
            parentState === OKRState.Open && entity.attributes.State === OKRState.Archived && actions.activateToClosed
                ? {
                    key: 'objActivateToClosed',
                    name: 'Activate to Closed',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.activateToClosed!(entity.id, OKRState.Closed, parent?.id, entity.attributes.State)
                } : undefined,
            entity.attributes.State === OKRState.Open && actions.close
                ? {
                    key: 'objClose',
                    name: 'Close',
                    iconProps: { iconName: "Encryption" },
                    onClick: () => actions.close!(entity.id, OKRState.Closed, parent?.id, entity.attributes.State)
                } : undefined,
            parentState === OKRState.Open && (entity.attributes.State === OKRState.Open || entity.attributes.State === OKRState.Closed) && actions.archive
                ? {
                    key: 'objArchive',
                    name: 'Archive',
                    iconProps: { iconName: "Archive" },
                    onClick: () => actions.archive!(entity.id, OKRState.Archived, parent?.id, entity.attributes.State)
                } : undefined
        ].filter(notUndefined)
        : [];
}

export function getKeyResultStateMenuItems(entity: KeyResult, parent: { state: OKRState, id: string, isEditable: boolean }, actions: OKRStateMenuAction): IContextualMenuItem[] {
    const parentState = parent.state ?? OKRState.Open;
    return parent.isEditable
        ? [
            parentState !== OKRState.Closed && entity.state === OKRState.Closed && actions.reopen
                ? {
                    key: 'objReopen',
                    name: 'Reopen',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.reopen!(entity.id, OKRState.Open, parent?.id, entity.state)
                } : undefined,
            parentState === OKRState.Open && entity.state === OKRState.Archived && actions.activateToOpen
                ? {
                    key: 'objActivateToOpen',
                    name: 'Activate to Open',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.activateToOpen!(entity.id, OKRState.Open, parent?.id, entity.state)
                } : undefined,
            parentState === OKRState.Open && entity.state === OKRState.Archived && actions.activateToClosed
                ? {
                    key: 'objActivateToClosed',
                    name: 'Activate to Closed',
                    iconProps: { iconName: "Refresh" },
                    onClick: () => actions.activateToClosed!(entity.id, OKRState.Closed, parent?.id, entity.state)
                } : undefined,
            entity.state === OKRState.Open && actions.close
                ? {
                    key: 'objClose',
                    name: 'Close',
                    iconProps: { iconName: "Encryption" },
                    onClick: () => actions.close!(entity.id, OKRState.Closed, parent?.id, entity.state)
                } : undefined,
            parentState === OKRState.Open && (entity.state === OKRState.Open || entity.state === OKRState.Closed) && actions.archive
                ? {
                    key: 'objArchive',
                    name: 'Archive',
                    iconProps: { iconName: "Archive" },
                    onClick: () => actions.archive!(entity.id, OKRState.Archived, parent?.id, entity.state)
                } : undefined
        ].filter(notUndefined)
        : [];
}