import "./EditableGridCell.css";
import * as React from "react";
import { DisplayFieldService } from "../DisplayFieldService";
import { useDebounce, useOuterClick } from "../../utils/effects";
import * as Metadata from "../../../entities/Metadata";
import { Dictionary, EntityType, IExtensibleEntity } from "../../../entities/common";
import { IValidator } from "../../../validation";

export type FieldCustomProps = {
    customFieldElementRender?: Dictionary<any>,
    customFieldValidatorBuilder?: Dictionary<(state: IExtensibleEntity, field: Metadata.Field) => IValidator>
};

export type GridCellProps = {
    item: IExtensibleEntity,
    field: Metadata.Field,
    entityType?: EntityType,
    onEditComplete: (value: any) => void,
    withEditIcon?: boolean,
    className?: string,
    readonly?: boolean,
} & FieldCustomProps;

export const getItemValue = (props: GridCellProps) => props.item.attributes[props.field.name];

export const EditorGridCell = (props: GridCellProps & { onClose: () => void }) => {
    const [value, setValue] = React.useState(getItemValue(props));
    const [isChanged, setIsChanged] = React.useState(false);

    const validator = React.useMemo(() => {
        return props.customFieldValidatorBuilder?.hasOwnProperty(props.field.name)
            ? props.customFieldValidatorBuilder[props.field.name]?.(props.item, props.field)
            : DisplayFieldService.buildValidatorFromField(props.field);
    }, [props.item, props.field, props.customFieldValidatorBuilder]);

    const _onEditComplete = useDebounce<(newValue?: any) => void>((newValue?: any) => {
        let val = value;
        if (newValue != null) {
            setValue(newValue);
            setIsChanged(true);
            val = newValue;
        }

        if (!validator || validator.isValid(val)) {
            if (isChanged) {
                props.onEditComplete(val!);
            }
        } else {
            setValue(getItemValue(props));
        }

        props.onClose();
    }, 100); 

    const listener = React.useCallback(() => _onEditComplete(), []);

    const ref = useOuterClick<HTMLDivElement>(listener);
    const inputProps = {
        value: value,
        item: props.item,
        onEditComplete: _onEditComplete,
        onChanged: (newValue: any) => {
            setValue(newValue);
            setIsChanged(true);
        },
        onBlur: () => _onEditComplete(),
        inputRef: (_: any) => { _?.focus(); },
    };

    var editor = React.useMemo(() => {
        return props.customFieldElementRender?.hasOwnProperty(props.field.name)
            ? props.customFieldElementRender[props.field.name]?.(inputProps, props.item, props.field, validator)
            : DisplayFieldService.buildGridEditorFieldInputElement(props.field, inputProps, props.entityType, undefined, undefined, validator);
    }, [props.customFieldElementRender, props.customFieldValidatorBuilder, props.field, props.item, props.entityType]);

    return <div ref={ref}
        className={`grid-editable-cell in-edit-mode ${props.className ?? ''}`}
        data-selection-disabled
        onClick={e => e.stopPropagation()} >
        {editor}
    </div>;
}
