import * as React from 'react';
import DropdownInput from './DropdownInput';
import { IInputProps } from '../interfaces/IInputProps';
import { FlagFormatter } from '../formatters/FlagFormatter';

const yesValue = "Yes";
const noValue = "No";

export const FlagEditor = (props: IInputProps) => {
    return <DropdownInput {...props}
        onChanged={(fieldValue: string) => props.onChanged?.(fieldValue === yesValue)}
        onEditComplete={(value: string) => props.onEditComplete?.(value === yesValue) }
        value={props.value ? yesValue : noValue}
        inputProps={{
            options: [
                { key: yesValue, text: "Yes" },
                { key: noValue, text: "No" }
            ],
            onRenderCaretDown: () => null,
            onRenderOption: (p: any) => <FlagFormatter value={p.key === yesValue} />,
            onRenderTitle: (p: any) => !p || !Array.isArray(p)
                ? <></>
                : <FlagFormatter value={p[0].key === yesValue} />,
        }} />;
}