import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import AccordionSection from "../AccordionSection";
import { IContextualMenuItem, Icon, IconButton, IButton, IRefObject } from 'office-ui-fabric-react';
import createLazyContainer from './../../../lazyContainer';
import { IControlConfiguration, ISectionUIControlProps, UIControlHeaderProps } from "../interfaces/ISectionUIControlProps";
import { Dictionary, IWarning, UserPreferencesSettingsUpdate } from "../../../entities/common";
import SectionReports, { ISectionReports } from '../../reporting/SectionReports';
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { notEmpty } from '../../utils/common';
import InaccessibleSection from './InaccessibleSection';

interface ISectionControlProps {
    section: ISectionInfo;
    index: number;
    user: UserState;
    entity: { id: string, isEditable?: boolean; canConfigure?: boolean; canCollaborate?: boolean; };
    onChange: (index: number, changes: Partial<ISectionInfo>) => void;
    onHeaderMiddleRender?: () => JSX.Element | JSX.Element[] | null;
    onSaveSettings?: (sectionId: string, update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => void;
    warnings?: IWarning[];
    reporting?: ISectionReports;
    isViewSelected?: boolean;
    controlsConfig?: Dictionary<IControlConfiguration>;
    sectionSettings?: Dictionary<unknown>;

    onToggleFullScreen: (value: boolean) => void;
    isFullScreen: boolean;
};

export interface ISectionInfo extends Metadata.Section {
    isConfigureMode: boolean;
    isOpen: boolean;
    isAccessible: boolean;
}

const configurableControls = new Set(['FieldsArea', 'StatusesControl', 'KPIControl', 'ScheduleControl', 'EmbeddedContentControl', 'LinkedRoadmapControl']);

type SectionElement = {
    key: string;
    header?: JSX.Element;
    menuItems?: IContextualMenuItem[];
    hideReports?: boolean;
    body: JSX.Element;
    helpUrl?: string;
}

const controls: Dictionary<any> = {};

const SectionControl = React.forwardRef<AccordionSection, ISectionControlProps>((props, ref) => {
    const { section, controlsConfig, sectionSettings, entity, warnings, isViewSelected, isFullScreen, user, onToggleFullScreen } = props;

    const onSectionChange = (changes: Partial<ISectionInfo>) => {
        props.onChange(props.index, changes);
    }

    const toggleFullScreen = () => {
        onToggleFullScreen(!isFullScreen);
        onSectionChange({ isOpen: true });
    }

    const fullScreenBtn = React.createRef<IButton>();
    React.useLayoutEffect(() => {
        if (isFullScreen) {
            fullScreenBtn.current?.focus();
        }
    }, [isFullScreen]);

    React.useEffect(() => () => {
        if (isFullScreen) {
            toggleFullScreen();
        }
    }, []);

    const onSaveSettings = (uiControlId: string, update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => {
        const updateWithUiControlId: UserPreferencesSettingsUpdate = {
            ...update,
            parentSettingsPathKeys: [uiControlId, ...update.parentSettingsPathKeys]
        };
        props.onSaveSettings?.(section.id, updateWithUiControlId, sendToServer);
    }

    const commonControlsProps = {
        entity: entity,
        warnings: warnings,
        isConfigureMode: section.isConfigureMode,
        isViewSelected: isViewSelected,
        sectionId: section.id,
        sectionLabel: Metadata.getLabel(section),
        isFullScreen: isFullScreen
    };

    const elements: SectionElement[] = section.uiControls.map((uiControl, index) => {
        const config = controlsConfig?.[uiControl.type] ?? {};
        const settings = (config.settingsBuilder
            ? config.settingsBuilder?.((sectionSettings?.[uiControl.id]) as any ?? Empty)
            : sectionSettings?.[uiControl.id]) ?? Empty;

        let control = controls[uiControl.type];
        if (!control) {
            control = controls[uiControl.type] = createLazyContainer<ISectionUIControlProps>(() => import(`./uiControls/${uiControl.type}.tsx`));
        }

        const controlProps: ISectionUIControlProps = {
            ...commonControlsProps,
            id: uiControl.id,
            key: uiControl.id,
            actions: config.actions ?? Empty,
            editProps: {
                uiControlElementsCustomRender: config.editProps?.elementCustomRender,
                customFieldValidatorBuilder: config.editProps?.customFieldValidator,
            },
            inlineEditProps: {
                uiControlElementsCustomRender: config.inlineEditProps?.elementCustomRender,
                customFieldValidatorBuilder: config.inlineEditProps?.customFieldValidator,
            },
            datacontext: config.datacontext,
            controlSettings: settings,
            settings: uiControl.settings,
            switchToConfigureMode: () => onSectionChange({ isConfigureMode: true }),
            onConfigureModeComplete: () => onSectionChange({ isConfigureMode: false }),
            onSaveSettings: props.onSaveSettings
                ? (update, sendToServer) => onSaveSettings(uiControl.id, update, sendToServer)
                : undefined,
        }

        const headerProps: UIControlHeaderProps<unknown> = {
            settings: settings,
            isLoaded: !!props.onSaveSettings,
            onChange: ({ expand, update }) => {
                onSectionChange({ isOpen: expand });
                onSaveSettings(uiControl.id, { parentSettingsPathKeys: [], valueBySettingNameMap: update });
            }
        }

        return {
            key: uiControl.id,
            header: config.headerRender?.(headerProps),
            menuItems: config.buildMenuItems?.(headerProps, controlProps),
            hideReports: config.hideReports,
            body: React.createElement(control, controlProps),
            helpUrl: config.helpUrl
        }
    });

    const onKeyDown = (ev: React.KeyboardEvent<any>) => {
        if (isFullScreen && ev.key === "Escape") {
            toggleFullScreen();
        }
    }

    const onSectionStateChange = () => {
        if (!isFullScreen) {
            onSectionChange({ isOpen: !section.isOpen });
        }
    }

    return <AccordionSection
        isOpen={section.isOpen || isFullScreen}
        className={`section ${section.isConfigureMode ? "configure-mode" : ""} ${isFullScreen ? 'maximized' : ""}`}
        ref={ref}
        onExpand={() => !isFullScreen && onSectionChange({ isOpen: true })}
        onCollapse={() => !isFullScreen && onSectionChange({ isOpen: false })}
        onRenderHeader={() => <SectionHeader
                {...props}
                ref={fullScreenBtn}
                sectionElements={elements}
                isFullScreen={isFullScreen}
                toggleFullScreen={toggleFullScreen}
                onChange={onSectionChange}
                onClick={onSectionStateChange}
            />}
        onRenderBody={() => section.isAccessible 
            ? <>{elements.map(_ => _.body)}</>
            : <InaccessibleSection sectionName={section.name} permissions={user.permissions.common}/>
        }
        onKeyDown={onKeyDown}
    />
});

const Empty = {}

type SectionHeaderProps = {
    section: ISectionInfo;
    user: UserState;
    entity: { isEditable?: boolean; canConfigure?: boolean; canCollaborate?: boolean; };
    onChange: (changes: Partial<ISectionInfo>) => void;
    onHeaderMiddleRender?: () => JSX.Element | JSX.Element[] | null;
    reporting?: ISectionReports;
    isViewSelected?: boolean;
    isFullScreen: boolean;
    toggleFullScreen: () => void;

    sectionElements: SectionElement[];
    onClick: () => void;
}

const SectionHeader = React.forwardRef((props: SectionHeaderProps, fullscreenBtn: IRefObject<IButton>) => {
    const { section, user, sectionElements, isViewSelected, entity, onChange,
        onHeaderMiddleRender, reporting, isFullScreen, toggleFullScreen, onClick } = props;
    const isConfigurable = section.uiControls.length === 1 && configurableControls.has(section.uiControls[0].type);
    const items = !section.isConfigureMode && isConfigurable
        ? [{
            key: 'configure',
            iconProps: { iconName: 'Settings' },
            name: 'Configure',
            disabled: !contains(user.permissions.common, CommonOperations.ConfigurationView)
                && (isViewSelected || !entity.canConfigure) || !section.isAccessible,
            title: entity.canConfigure && isViewSelected && !contains(user.permissions.common, CommonOperations.ConfigurationManage)
                ? 'Please select default View to enable configuration.'
                : '',
            onClick: () => onChange({ isOpen: true, isConfigureMode: true })
        }]
        : [];

    return <div className="align-center">
        <div className="title align-center" onClick={onClick}>
            <span className="chevron"></span>
            {section.settings.iconName && <Icon iconName={section.settings.iconName} style={{ filter: 'grayscale(1)' }} />}
            <span>{Metadata.getLabel(section)}</span>
        </div>
        {onHeaderMiddleRender?.()}
        <div className="menu align-center" onClick={(e) => e.stopPropagation()}>
            {
                sectionElements.filter(_ => _.header).map(_ =>
                    <React.Fragment key={_.key}>{_.header}</React.Fragment>)
            }
            {
                <SectionReports
                    reporting={sectionElements.some(_ => _.hideReports) ? undefined : reporting}
                    items={items}
                    entity={entity}
                    sectionName={section.name}
                    getMenuItems={() => sectionElements.map(_ => _.menuItems).filter(notEmpty).reduce((p, c) => p.concat(c), [])}
                />
            }
            {
                sectionElements.filter(_ => _.helpUrl).map(_ =>
                    <IconButton
                        key='help'
                        iconProps={{ iconName: "PPMXQuestionMarkInCircle" }}
                        title={`${section.name} help`}
                        href={_.helpUrl}
                        target="_blank" />)
            }
            <IconButton
                key='fullscreen'
                componentRef={fullscreenBtn}
                title={`${!isFullScreen ? "Enter" : "Exit"} full screen mode`}
                iconProps={{ iconName: isFullScreen ? "BackToWindow" : "Fullscreen" }}
                onClick={toggleFullScreen} />
        </div>
    </div>
});

export default SectionControl;