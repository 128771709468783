import { Icon } from "office-ui-fabric-react";
import React from "react";
import { InlineEditorProps } from "../interfaces/IInputProps";

export type DropdownOptionData = {
    cssClassName?: string;
    backgroundColor?: string;
}

type Props = {
    selected?: boolean;
    text: string;
    data?: DropdownOptionData;
}

const ColorStatusDropdownOption = (props: Props & InlineEditorProps) =>
    props.isInlineEditor
        ? <InlineColorStatusDropdownOption {...props} />
        : <DefaultColorStatusDropdownOption {...props} />;

const DefaultColorStatusDropdownOption = (props: Props) => {
    const { data, text, selected } = props;
    return (
        <span className={`color-status overflow-text ${data?.cssClassName ?? ""}`}>
            {
                selected !== undefined && <span className={`is-selected ${selected ? "selected" : ""}`}>
                    <Icon iconName="CheckMark" />
                </span>
            }
            {data?.backgroundColor && <span
                className='status'
                style={{ backgroundColor: data.backgroundColor }}
            ></span>}
            <span className="value">{text}</span>
        </span>
    )
}

const InlineColorStatusDropdownOption = (props: Props) => {
    const { data, text } = props;
    return <div className="color-status-wrapper" style={data?.backgroundColor ? { backgroundColor: data.backgroundColor } : undefined} >
       {text}
    </div>;
}

export default ColorStatusDropdownOption;