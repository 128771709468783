import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as analytics from '../../analytics';
import { ApplicationState } from '../../store';
import * as ChallengesListStore from '../../store/ChallengesListStore';
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from '../../store/layouts';
import * as ViewsStore from '../../store/views';
import * as IdeasListStore from '../../store/IdeasListStore';
import { LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import { DetailsSpinner } from "../common/Spinner";
import { Dictionary, EntityType } from '../../entities/common';
import { bindActionCreators } from 'redux';
import ExternalEpmConnectControl from '../portfolio/ExternalEpmConnectControl';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { TeamsChannelLink, TeamsChannelActions } from '../integration/TeamsChannelConnectControl';
import { ILinkDto } from '../../store/integration/common';
import { buildActions, urlParamsBuilder, buildIdeaActions } from '../../entities/Subentities';
import { Idea } from '../../store/IdeasListStore';
import { UserState, isInReadonlyMode } from '../../store/User';
import { IIdeasConfiguration } from '../common/sectionsControl/uiControls/IdeasControl';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { withViewType } from '../common/ViewTypeSelect';
import { Views } from '../../store/services/viewSaver';
import * as EmbeddedContentControl from '../common/sectionsControl/uiControls/EmbeddedContentControl';
import ChallengeHeader from './ChallengeHeader';
import { IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';
import * as LinkedRoadmapControl from '../common/sectionsControl/uiControls/LinkedRoadmapControl';
import { withLinkedRoadmapSettings } from '../common/sectionsControl/uiControls/LinkedRoadmapControl';
import { buildIWithBenefitsCustomRenders, buildIWithBenefitsValidators, rendersBuilder, validators } from '../field/Fields';

type StateProps = {
    user: UserState;
    entity?: ChallengesListStore.Challenge;
    isLoading: boolean;
    isUpdatingSections: boolean;
    layouts: LayoutsStore.LayoutsState;
    ideasViewId?: string;
    isReadonlyMode: boolean;
}

type ChallengeDetailsState = {
    isCofigurationPanelOpen: boolean;
}

type ActionProps = {
    challengeActions: typeof ChallengesListStore.actionCreators,
    notificationsActions: typeof Notifications.actionCreators,
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>,
    ideaViewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>,
    ideaActions: typeof IdeasListStore.actionCreators,
}

type ChallengeDetailsProps = RouteComponentProps<{ id: string }>
    & StateProps
    & ActionProps;

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    IdeasControl: IIdeasConfiguration;
    EmbeddedContentControl: EmbeddedContentControl.IConfiguration;
    LinkedRoadmapControl: LinkedRoadmapControl.IConfiguration;
}

const entityName = EntityType.Challenge;
class ChallengeDetails extends React.Component<ChallengeDetailsProps, ChallengeDetailsState> {
    constructor(props: ChallengeDetailsProps) {
        super(props);
        this.state = {
            isCofigurationPanelOpen: false
        };
    }

    componentWillMount() {
        this._handleView(this.props);
        this.props.challengeActions.loadChallenge(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps: ChallengeDetailsProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.props.challengeActions.loadChallenge(nextProps.match.params.id);
        }

        if (this.props.ideasViewId !== nextProps.ideasViewId) {
            this._handleView(nextProps);
        }
    }

    public render() {
        const { entity, isLoading, layouts, isUpdatingSections } = this.props;
        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ChallengeHeader
                entity={entity}
                actions={{
                    openConfigurationPanel: this.openConfigurationPanel,
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    layoutActions: {
                        viewLayout: this.viewLayout,
                        applyLayout: this.applyLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    removeChallenge: this.removeChallenge,
                    changeDefaultIdeaLayout: this.changeDefaultIdeaLayout
                }}
            />}
            {
                entity && entity.sections && <LayoutableSectionsContainer
                    key="sections"
                    entity={entity}
                    entityType={EntityType.Challenge}
                    layouts={layouts}
                    isUpdatingSections={isUpdatingSections}
                    controlsConfig={this._buildControlsConfigurations()}
                />
            }
            {
                entity && this.state.isCofigurationPanelOpen &&
                <ExternalEpmConnectControl
                    readonly={!entity.isEditable}
                    sourceInfos={entity.sourceInfos}
                    actions={this.epmConnectControlActionsBuilder()}
                    onDismiss={() => { this.setState({ isCofigurationPanelOpen: false }); }}
                    visibleTypes={[SourceType.O365TeamsChannel]}
                />
            }
        </DetailsSpinner>
    }

    private _handleView = (props: ChallengeDetailsProps) => {
        if (props.ideasViewId) {
            this.props.ideaViewsActions.setListActiveSubView(props.ideasViewId);
        }
    }

    private epmConnectControlActionsBuilder() {
        const teamsActions: TeamsChannelActions = {
            linkToTeamsChannel: this.linkToTeamsChannel,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365TeamsChannel)
        };
        return {
            [SourceType.O365TeamsChannel]: teamsActions
        };
    }

    private onEditComplete = (fieldName: string, fieldValue: any): void => {
        if (!fieldName) {
            return;
        }

        this.props.challengeActions.updateChallengeAttributes(this.props.entity!.id, { [fieldName]: fieldValue });
    }

    private applyLayout = (layout: Metadata.Layout) => {
        this.props.challengeActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.challengeActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.challengeActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.challengeActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Metadata.Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: Notifications.NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Metadata.Section[], update: Metadata.IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: Notifications.NotificationType.Info
        });
    }

    private removeChallenge = () => {
        this.props.challengeActions.removeChallenges([this.props.entity!.id], true);
    }

    private linkToTeamsChannel = (linkData: ILinkDto<TeamsChannelLink>) => {
        this.props.challengeActions.linkToTeamsChannel(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking challenge '${this.props.entity!.attributes.Name}' to teams channel has been started.` });
        analytics.trackLink('Linked challenge to teams channel', this.props.user);
    }

    private deleteLink = (connectionId: string, type: SourceType) => {
        this.props.challengeActions.deleteChallengeToExternalSystemLink(this.props.entity!.id, connectionId, type);
        this.props.notificationsActions.pushNotification({ message: `Unlinking challenge '${this.props.entity!.attributes.Name}' has been started.` });
    }

    private openConfigurationPanel = () => {
        this.setState({ isCofigurationPanelOpen: true });
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { entity, challengeActions, layouts, user, isReadonlyMode } = this.props;
        const analyticsProps = { user: user, parentType: EntityType.Challenge };
        const { entityId, entityActions, actions } = buildActions(entity!.id, isReadonlyMode, analyticsProps, layouts, challengeActions, challengeActions.loadChallenge);
        return {
            ['FieldsArea']: {
                datacontext: { entityId, entityType: EntityType.Challenge },
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditComplete
                }
            },
            ["IdeasControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildIdeaActions(entityId, actions, challengeActions.removeIdea, this.bulkApplyIdeasLayout),
                inlineEditProps: {
                    customFieldValidator: { ...validators, ...buildIWithBenefitsValidators() },
                    elementCustomRender: { ...rendersBuilder(), ...buildIWithBenefitsCustomRenders() },
                }
            },
            ['EmbeddedContentControl']: {
                datacontext: { entityType: EntityType.Challenge },
                helpUrl: "https://help.ppm.express/89502-ppm-express-how-to-articles/2416583",
                actions: {
                    updateUiControl: entityActions.updateUIControl
                }
            },
            ['LinkedRoadmapControl']:
                withLinkedRoadmapSettings(entity, EntityType.Challenge, user, this.props.isReadonlyMode, entityActions.updateUIControl)

        }
    }

    private updateImage = (image: File) => {
        this.props.challengeActions.updateImage(this.props.entity!.id, image);
    }

    private removeImage = () => {
        this.props.challengeActions.removeImage(this.props.entity!.id);
    }

    private changeDefaultIdeaLayout = (layout: Metadata.Layout) => {
        this.props.challengeActions.setDefaultIdeaLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({
            message: `Layout '${layout.name}' was selected as default for new ideas in this Business Challenge`,
            type: Notifications.NotificationType.Info
        });
    }

    private bulkApplyIdeasLayout = (layout: Metadata.Layout, ideas: Idea[]) => {
        this.props.ideaActions.bulkApplyLayout(ideas.map(_ => _.id), layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    return {
        user: state.user,
        entity: state.challenges.activeEntity && state.challenges.activeEntity.id === ownProps.match.params.id ? state.challenges.activeEntity : undefined,
        isLoading: state.challenges.isLoading || !!state.layouts[entityName].isApplyingLayout,
        isUpdatingSections: state.challenges.isUpdatingSections,
        layouts: state.layouts[entityName],
        ideasViewId: (new URLSearchParams(ownProps.location.search)).get(urlParamsBuilder.view(EntityType.Idea)) ?? undefined,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(
    mapStateToProps, (dispatch): ActionProps =>
({
    challengeActions: bindActionCreators(ChallengesListStore.actionCreators, dispatch),
    notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
    layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
    ideaViewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Idea), dispatch),
    ideaActions: bindActionCreators(IdeasListStore.actionCreators, dispatch),
}))(ChallengeDetails);