import React from 'react';
import "./FlagFormatter.css";
import { Icon } from 'office-ui-fabric-react';

type FlagFormatterProps = {
    value?: boolean,
    yesIconName?: string,
    noIconName?: string,
}

export const FlagFormatter = (props: FlagFormatterProps) => {
    if (props.value) {
        return <div className='flag yes'>
            <Icon iconName={props.yesIconName ?? 'SkypeCircleCheck'} /> Yes
        </div>;
    }
    return <div className='flag no'>
        <Icon iconName={props.noIconName ?? 'Blocked2'} /> No
    </div>;
}
